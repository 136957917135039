import {
  ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'ngx-flip',
  template: `<div class="flipper" #flipContainer>
<div class="front">
  <ng-content select="[front]" ></ng-content>
</div>
  <div class="back">
  <ng-content select="[back]" ></ng-content>
  </div>
</div>

`,
  styles: [`:host{-webkit-perspective:1000px;perspective:1000px;display:block}:host .flipper{transition:.6s;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;height:100%;width:100%;position:relative}:host .back,:host .front{width:100%;height:100%;-webkit-backface-visibility:hidden;backface-visibility:hidden;position:absolute;top:0;left:0}:host .front{z-index:2;-webkit-transform:rotateY(0);transform:rotateY(0)}:host .back{-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}:host .rotate{-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlipComponent implements OnInit, OnChanges {

  constructor(private renderer: Renderer2) {
  }

  @ViewChild('flipContainer') private flipContainer;
  @Input() flip: boolean;

  ngOnInit() {
    this.rotate();
  }
  ngOnChanges(change: SimpleChanges) {
    this.rotate();
  }
  rotate() {
    if (this.flip) {
      this.renderer.addClass(this.flipContainer.nativeElement, 'rotate');
    } else {
      this.renderer.removeClass(this.flipContainer.nativeElement, 'rotate');
    }

  }

}
